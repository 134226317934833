/* HEADER CSS */
.navigationRoot {
    position: sticky;
    top: 0;
    z-index: 900;
    padding: 0.5 1rem;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    width: 100%;
}

.cdphDvrImg {
    height: 64px;
    padding-left: 5px;
}

.linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    position: relative;
}

.linkText {
    color: black;
    text-align: center;
    padding: 1rem 0;
    display: inline-block;
    text-align: center;
}

.linkText:hover {
    font-weight: bold !important;
    color: black;
    /* letter-spacing: 0; */
}

.linkText:before {
    color: black;
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.rightItemsNavContainer {
    display: flex;
    flex-direction: row;
}

.navItemDropdownMenu {
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 0px 2px 3px 2px #dddbda;
    background-color: #ffffff;
    list-style: none;
    padding: unset;
    margin: unset;
    width: auto;
    top: 48px;

}

.navItemDropdownOption {
    background-color: white;
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 12px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
}

.navItemDropdownOption:hover {
    background-color: #f3f2f2;
}

.linkSelect {
    text-decoration: none;
    color: black;
}

span.arrow {
    position: relative;
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid #706e6b;
    margin: 4px 0 5px 6px;
  }
  
  span.downArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    position: relative;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #706e6b;
    margin: 6px 0 2px 6px;
    display: inline-block;
  }

.hamburgerIcon {
    display: none;
}

.navigationContainerMobile {
    display: none;
}

/* FOOTER CSS */
.footerContainer {
    background-color: #ffffff;
    padding: 2.5rem 3rem 3rem 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
}

.footerSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.leftSectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 2rem;
    max-width: 30vw;
    line-height: 18px;

}

.footerCDPHImg {
    width: 150px;
    height: auto;
    display: block;
    align-self: self-start;
}

.vaLinkText {
    color: #0d6efd;
    margin: 0;
    text-decoration: underline;
}

.rightSectionContainer {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    width: 20vw;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.footerCAGovImg {
    display: block;
    align-self: self-start;
    padding: 0.5rem;
}

.privacyPolicyText {
    color: #0d6efd;
    margin: 0 0 0 5px;
    text-decoration: underline;
}



@media screen and (max-width: 775px) {
    /* HEADER CSS MOBILE */

    .navigationRoot {
        justify-content: space-between;
    }

    .hamburgerIcon {
        display: block;
    }

    .rightItemsNavContainer {
        margin-right: 50px;
    }

    .navigationButtonsMobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .linkContainer {
        display: none;
    }

    .cdphDvrImg {
        height:46px;
        padding-left: 10px;
    }    

    .navigationContainer {
        justify-content: space-between;
        padding: 0.5rem 2rem 0.5rem 0.5rem;

    }

    .navigationContainerMobile {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        width: 100%;
        top: 6rem;
        left: 0;
        position: absolute;
        box-shadow: inset 0px 5px 2px 0px rgba(0, 0, 0, 0.2);
        padding: 30px 0;
        z-index: 900;
        background-color: white;
    }

    .backArrowIcon {
        margin-left: 2rem;
        margin-right: 1.4rem;
    }

    .xIcon {
        width: 13px;
        height: auto;
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .linkContainerMobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1.5rem;
        margin-left: 2rem;
    }

    .myTurnMenuMobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1.5rem;
        margin-left: 4rem;
    }

    .linkTextMobile {
        font-weight: 400;
        padding: 8px 0;
        color: black;
    }

    .linkArrowMobile {
        margin-left: 7px;
        width: 6px;
    }

    body[dir="rtl"] .linkTextMobile {
        font-weight: 400;
        padding: 8px 20px;
    }

    a.linkTextMobile:active {
        color: #0A58CA;
    }

    /* FOOTER MOBILE CSS */

    .footerContainer {
        justify-content: none;
        flex-wrap: wrap;
        padding: 2rem 1rem 1rem 1rem;
    }

    .leftSectionContainer {
        margin-left: 2rem;
        max-width: none;
    }

    .footerSection {
        padding-bottom: 1.5rem;
    }

    .footerCDPHImg {
        width: 130px;
    }

    .rightSectionContainer {
        margin-left: 2rem;
        width: 100%;
    }

    .footerCAGovImg {
        width: 130px;
        height: auto;
        display: block;
        align-self: center;
    }

}