.mobile-margin-container{
    margin: 4vw;
}

.record-header-container-mobile{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.personal-info-container-mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.personal-info-text-mobile{
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

#personal-info-download-button-mobile{
    display: flex;
    background-color: white;
}


.vaccine-group-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.vaccine-button-container {
    margin-bottom: 15px;
    text-align: center;
}

#button-style{
    width: 95%;
    border: 3px solid;
    border-radius: 12px;
    border-left: 30px solid;
    font-size: 16px;
    text-transform: none;
    font-weight: bold;
    text-align: left;
}

#modal-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    background-color: white;
    border-radius: 12px;
    box-shadow: 24;
}

.modal-vaccine-title-container {
    border: 3px solid;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 15px 5px 15px 15px;
}

.x-image-style {
    position: relative;
    width: 20px;
}

.modal-vaccine-info-container {
    margin: auto;
    /* margin: px; */
}

.modal-pers-info-container {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 14px;
}

.modal-pers-info-container-header {
    font-size: 16px;
}

.modal-vaccine-doses-container {
    margin-top: 20px;
    overflow-y: auto;
    max-height: 50vh;
}

.modal-recommendation-container {
    margin-top: 20px;
    overflow-y: auto;
    max-height: 50vh;
    font-weight: bold;
}

.modal-vaccine-doses {
    font-size: 14px;
}

.modal-vaccine-doses-header {
    font-size: 16px;
}

.modal-vaccine-doses-scroll{
    margin-left: 20px;

}
   
.myTurnFooter {
    background-color: #22489C;
    color: white;
    border-end-end-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 24;
    padding: 22px 22px;
    display: flex;
    width: 100%; 
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; 
}

span.myTurnText {
    display: flex;
    text-align: center;
    padding-bottom: 12px;
}

button.buttonStyle {
    background-color: white;
    color: #22499c;
    border-radius: 2.7rem;
    border-width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    height: 39px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
  }


  @media only screen and (orientation: landscape) {

    .modal-vaccine-info-container {
        padding: 10px; 
        margin: 0 auto; 
        max-height: 70vh; 
        overflow: auto;
        box-sizing: border-box;
    }

    .myTurnFooter {
        position: relative;
        width: 100%; 
    }

    .modal-vaccine-info-container {
        margin: -10px;
        width: auto;
    }
    
  }