div.root {
    background-color: #FAEEE8;
    width: 100vw;
    max-width: 100%;
    height: 400px;
    line-height: normal;
}

div.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: auto;
    max-width: 1440px;
    min-height: 20rem;
    height: 100%;
    position: relative;
}

div.imageContainer {
    height: 100%;
}

img.superClinicsImg {
    width: 80%;
}

div.textContainer {
    max-width: 50%;
}

div.title {
    color: #4388C3;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 1rem;
}

p.text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.38px;
    margin-bottom: 1.5rem;
}

button.bttnStyle {
    background: #4388C3;
    color: #fff;
    font-size: 16px;
    line-height: 24.51px;
    font-weight: 700;
    cursor: pointer;
    padding: 6px 30px;
    border-radius: 4rem;
    border: none;
}


@media screen and (max-width: 950px) {
    div.root {
        height: auto;
    }
    
    div.container{
        flex-direction: column;
        min-height: 600px;
    }

    div.textContainer {
        max-width: 80%;
        margin-bottom: 2rem;
    }

    img.superClinicsImg {
        width: 325px;
    }

    div.title {
        font-size: 25px;
        margin-bottom: 1.5rem;
    }

    p.text {
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {
    div.container{
        justify-content: start;
    }

    div.imageContainer {
        height: 100%;
    }

    div.textContainer {
        margin-top: 1.5rem;
    }

    img.superClinicsImg {
        width: 300px;
    }

}