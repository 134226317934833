/* Commenting out - got rid of overflow */
/* html, body{
  overflow-x: hidden;
} */

.margin-container {
  margin: 3vw;
}

.title-style {
  color: #22489C;
  font-weight: bold;
  padding-top: 10px;
  font-size: 45px;
}

.personal-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.img{
  background-color: #FFFACD;
}

.vaccine-name-style {
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
}

.vaccine-group-container-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.save-button-container {
  display: flex;
  flex-direction: column;
}

#save-button-style {
  margin-top: 10px;
  height: 55px;
  width: 275px;
}

.button-hover:hover {
  color: #ffffff;
}

@media (max-width: 650px) {
  .save-button-container {
    margin-top: 10px;
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 300px) {
  #save-button-style {
    margin-top: 10px;
    height: 55px;
    width: 200px;
  }
}
