/* REACT TABS CSS */

.react-tabs {
  display: flex !important;
  flex-direction: row !important;
  margin: 2rem 0 !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0;
  width: 85%;
  /* background-color: yellow !important; */
}

.react-tabs__tab {
  top: 10rem;
  left: 0;
  position: absolute !important;
  /* display: flex !important; */
  justify-content: center !important;
  color: black !important;
  font-weight: 400 !important;
  flex-grow: 1 !important;
  width: 350px !important;
  /* position: inherit !important; */
  max-height: 43px !important;
  /* width: 100% !important; */
  height: 43px;
  padding: 15px 0 0 60px !important;
  border-radius: 5px 5px 8px 5px !important;
  /* background-color: red; */
}

.react-tabs__tab:first-child {
  top: 15rem;
  left: 0;
  position: absolute !important;
  /* display: flex !important; */
  justify-content: center !important;
  color: black !important;
  font-weight: 400 !important;
  flex-grow: 1 !important;
  width: 275px !important;
  /* positi5n: inherit !important; */
  max-height: 43px !important;
  /* width: 100% !important; */
  height: 43px;
  padding: 12px 30px 0 60px !important;
  border-radius: 5px 5px 8px 5px !important;
  /* background-color: red; */
}

.react-tabs__tab:nth-child(2) {
  top: 19rem;
  left: 0;
  position: absolute !important;
  /* display: flex !important; */
  justify-content: center !important;
  color: black !important;
  font-weight: 400 !important;
  flex-grow: 1 !important;
  width: 275px !important;
  /* positi5n: inherit !important; */
  max-height: 43px !important;
  /* width: 100% !important; */
  height: 43px;
  padding: 12px 30px 0 60px !important;
  border-radius: 5px 5px 8px 5px !important;
  /* background-color: red; */
}

.react-tabs__tab:nth-child(3) {
  top: 23rem;
  left: 0;
  position: absolute !important;
  /* display: flex !important; */
  justify-content: center !important;
  color: black !important;
  font-weight: 400 !important;
  flex-grow: 1 !important;
  width: 275px !important;
  /* positi5n: inherit !important; */
  /* max-height: 43px !important; */
  /* width: 100% !important; */
  height: 43px;
  padding: 12px 30px 0 60px !important;
  border-radius: 5px 5px 8px 5px !important;
  /* background-color: red; */
}

.react-tabs__tab--selected:first-child {
  /* top: 50rem;
      left: 0; */
  position: absolute !important;
  color: white !important;
  font-weight: 700 !important;
  margin-left: -4px !important;
  width: 275px !important;
  height: 40px !important;
  /* border-bottom: 10px solid #244c9c !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /* margin-bottom: -5px !important; */
  cursor: inherit !important;
  padding: 8px 25px 0 65px !important;
  border-radius: 5px 5px 8px 5px !important;
  background-color: #22499c !important;
}

.react-tabs__tab--selected:first-child::after {
  height: 0px !important;
}

.react-tabs__tab--selected:first-child span{
  /* top: 50rem;
      left: 0; */
  position: absolute !important;
  color: white !important;
  left: 0;
  top: 0;
  font-weight: 700 !important;
  width: 275px !important;
  height: 63px !important;
  /* border-bottom: 10px solid #244c9c !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /* margin-bottom: -5px !important; */
  cursor: inherit !important;
  padding: 8px 25px 0 65px !important;
  border-radius: 5px 5px 8px 5px !important;
  background-color: #22499c !important;
}


.react-tabs__tab--selected:nth-child(2) {
  /* top: 50rem;
      left: 0; */
  position: absolute !important;
  color: white !important;
  font-weight: 700 !important;
  margin-left: -4px !important;
  width: 275px !important;
  height: auto !important;
  /* border-bottom: 10px solid #244c9c !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /* margin-bottom: -5px !important; */
  cursor: inherit !important;
  padding: 8px 25px 0 65px !important;
  border-radius: 5px 5px 8px 5px !important;
  background-color: #22499c !important;
}

.react-tabs__tab--selected:nth-child(3) {
  /* top: 50rem;
      left: 0; */
  position: absolute !important;
  color: white !important;
  font-weight: 700 !important;
  margin-left: -4px !important;
  width: 275px !important;
  height: auto !important;
  /* border-bottom: 10px solid #244c9c !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /* margin-bottom: -5px !important; */
  cursor: inherit !important;
  padding: 8px 25px 0 65px !important;
  border-radius: 5px 5px 8px 5px !important;
  background-color: #22499c !important;
}

.react-tabs__tab--selected:nth-child(3)::after {
  height: 0px !important;
}

.react-tabs__tab--selected:nth-child(3) div {
  /* top: 50rem;
      left: 0; */
  position: absolute !important;
  color: white !important;
  left: 0;
  top: 0;
  font-weight: 700 !important;
  width: 275px !important;
  height: 63px !important;
  /* border-bottom: 10px solid #244c9c !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /* margin-bottom: -5px !important; */
  cursor: inherit !important;
  padding: 8px 25px 0 65px !important;
  border-radius: 5px 5px 8px 5px !important;
  background-color: #22499c !important;
}

.react-tabs__tab--selected:nth-child(3) span {
  /* top: 50rem;
      left: 0; */
  position: absolute !important;
  color: white !important;
  left: 0;
  top: 0;
  font-weight: 700 !important;
  width: 275px !important;
  height: 87px !important;
  /* border-bottom: 10px solid #244c9c !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  /* margin-bottom: -5px !important; */
  cursor: inherit !important;
  padding: 8px 25px 0 65px !important;
  border-radius: 5px 5px 8px 5px !important;
  background-color: #22499c !important;
}

@media screen and (max-width: 950px) {
  .cookieCrumbBanner {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .react-tabs {
    display: flex !important;
    flex-direction: column !important;
    margin: 2rem auto !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 0;
    width: 100%;
    /* background-color: yellow !important; */
  }

  .react-tabs__tab-list {
    display: flex !important;
    flex-direction: column;
    border: 0.78px solid #909090;
    border-bottom: 1px solid #aaa !important;
    width: auto !important;
    margin: 10px 0 10px 0 !important;
    /* background-color: pink; */
  }

  .react-tabs__tab-panel {
    padding-left: 0;
    margin: auto auto;
    width: 100%;

    /* justify-content: flex-start; */
    /* background-color: green !important; */
  }

  .react-tabs__tab:first-child {
    top: auto;
    left: auto;
    position: relative !important;
    /* display: flex !important; */
    justify-content: center !important;
    color: black !important;
    font-weight: 400 !important;
    flex-grow: 1 !important;
    width: auto !important;
    /* position: inherit !important; */
    max-height: 90px !important;
    /* width: 100% !important; */
    height: auto;
    padding: 15px 0 0 30px !important;
    border-radius: 5px 5px 8px 5px !important;
    margin-top: 0;
    border-bottom: none !important;
    /* background-color: red; */
  }

  .react-tabs__tab:nth-child(2) {
    top: auto;
    left: auto;
    position: relative !important;
    /* display: flex !important; */
    justify-content: center !important;
    color: black !important;
    font-weight: 400 !important;
    flex-grow: 1 !important;
    width: auto !important;
    /* position: inherit !important; */
    max-height: 90px !important;
    /* width: 100centermportant; */
    height: auto;
    padding: 10px 0 0 30px !important;
    border-radius: 5px 5px 8px 5px !important;
    border-bottom: none !important;
    /* background-color: red; */
  }

  .cookieCrumb {
    display: none;
  }

  .breadcrumb {
    display: none !important;
  }

  .react-tabs__tab:nth-child(3) {
    top: auto;
    left: auto;
    position: relative !important;
    /* display: flex !important; */
    justify-content: center !important;
    color: black !important;
    font-weight: 400 !important;
    flex-grow: 1 !important;
    width: auto !important;
    /* position: inherit !important; */
    max-height: 90px !important;
    /* width: 100% !important; */
    height: auto;
    padding: 10px 0 10px 30px !important;
    margin-bottom: 10px;
    border-radius: 5px 5px 8px 5px !important;
    border-bottom: none !important;
    /* background-color: red; */
  }
  .react-tabs__tab--selected:first-child {
    position: relative !important;
    color: white !important;
    font-weight: 700 !important;
    width: auto !important;
    margin-left: 0 !important;
    align-items: center !important;
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    /* margin-bottom: -5px !important; */
    cursor: inherit !important;
    padding: 10px 0 10px 30px !important;
    border-radius: 0 !important;
    background-color: #22499c !important;
  }

  .react-tabs__tab--selected:nth-child(2) {
    position: relative !important;
    color: white !important;
    font-weight: 700 !important;
    width: auto !important;
    margin-left: 0 !important;
    align-items: center !important;
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    /* margin-bottom: -5px !important; */
    cursor: inherit !important;
    padding: 10px 0 10px 30px !important;
    border-radius: 0 !important;
    background-color: #22499c !important;
  }

  .react-tabs__tab--selected:nth-child(3) {
    position: relative !important;
    color: white !important;
    font-weight: 700 !important;
    width: auto !important;
    margin-left: 0 !important;
    align-items: center !important;
    border-bottom: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    /* margin-bottom: -5px !important; */
    cursor: inherit !important;
    padding: 10px 0 10px 30px !important;
    border-radius: 0 !important;
    background-color: #22499c !important;
  }
}

/* FAQ QUESTION CSS */

div.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  /* padding: 1rem 0 3.5rem 5rem; */
  margin-left: -11.4rem;
  margin-right: auto;
  margin-bottom: 5rem;
  /* width: 100%; */
  /* max-width: 932px; */
  background-color: #ffffff;
  /* background-color: green; */
}

/* For RTL (right-to-left) languages, like Arabic */
body[dir="rtl"] .react-tabs {
  direction: rtl;
  padding-right: 5rem; 
}


body[dir="rtl"] .react-tabs__tab--selected {
  direction: rtl;
  padding-right: 10px !important;

}

body[dir="rtl"] .react-tabs__tab--selected:nth-child(2) {
  direction: rtl;
  padding-right: 10px !important;

}

body[dir="rtl"] .react-tabs__tab--selected:nth-child(3) {
  direction: rtl;
  padding-right: 10px !important;

}

body[dir="rtl"] .react-tabs__tab:first-child {
  direction: rtl;
  padding-right: 10px !important;

}

body[dir="rtl"] .react-tabs__tab:nth-child(2) {
  direction: rtl;
  padding-right: 10px !important;

}

body[dir="rtl"] .react-tabs__tab:nth-child(3) {
  direction: rtl;
  padding-right: 10px !important;

}

body[dir="rtl"] .infoBoxTitle {
  padding: 1rem 5rem 0 0; /* Adjust the value as needed */
}



a {
  margin: 0;
  padding: 0;
  /* Reset display property to inline to prevent any unwanted line breaks */
  display: inline;
  /* text-decoration: underline; */
}

h2.sectionTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: center;
  color: #22489c;
}

h4.subTitle {
  font-size: 1rem;
  /* line-height: 0.5rem; */
  text-align: center;
  margin-bottom: 3rem;
}

div.faqWrapper {
  border-top: 1px solid #b9b9b9;
  padding: 1.3rem 0;
  /* margin-right: 5rem; */
  display: flex;
  /* width: 70vw; */
  width: 80%;
  min-width: 5%;
  /* background-color: blue */
}

div.searchFaqWrapper {
  padding: 1.3rem 0;
  /* margin-right: 5rem; */
  display: flex;
  /* width: 70vw; */
  width: 80%;
  min-width: 5%;
  /* background-color: blue */
}

div.faqWrapper:last-child {
  border-bottom: none;
}

div.faqWrapper:first-child {
  border-top: none;
}

div.faqItem {
  width: 100%;
}

div.questionWrapper {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  /* margin: 1rem; */
}

p.question {
  display: block;
  width: 90%;
  /* margin-left: 1rem; */
  color: #333;
  font-size: 18px;
  font-weight: 700;
}


p.question-flu {
  display: block;
  width: 90%;
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 700;
}

div.answer {
  font-size: 16px;
  font-weight: 400;
  width: 90%;
  /* margin: 2rem 0 1rem 0; */
  /* margin-left: 1rem; */
  max-width: 1000px;
  line-height: 25px;
}

.answer a {
  color: blue;
  text-decoration: underline;
}

button.toggle {
  display: block;
  width: 2%;
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1.5rem;
  height: 1.5rem;
  color: #244c9c;
}

/* breadcrumbs */

.cookieCrumb {
  margin-top: 2rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
}

.cookieCrumbBanner {
  margin-top: 2rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  position: absolute;
  top: 9rem;
}

.breadcrumb {
  padding-left: 0;
  background-color: transparent;
  font-size: 16px;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;


.breadcrumb li {
  position: relative;
  padding-left: 15px;
  padding-right: 15px; /* Add padding to both sides for space */
  direction: ltr; /* Set direction to LTR */
}

.breadcrumb li:before {
  content: "";
  border-left: 1px dotted #777777;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.breadcrumb li:first-child:before {
  display: none; /* Hide the separator for the first li */
}

  .list-group-flush a {
    color: #004abc;
  }

  .list-group-flush a,
  a,
  a:hover,
  a:visited {
    color: #004abc;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  a.list-group-item {
    padding: 20px;
    margin-left: 10px;
  }
}



/* For RTL (right-to-left) languages, like Arabic */
body[dir="rtl"] .cookieCrumb {
  margin-top: 2rem;
  margin-right: 4rem; /* Adjust margin-right for RTL */
  margin-bottom: 1rem;
}

body[dir="rtl"] .breadcrumb li {
  padding-left: 15px; /* Adjust padding for RTL */
  padding-right: 15px; /* Add padding to both sides for space */
  direction: rtl; /* Set direction to RTL */
}

body[dir="rtl"] .breadcrumb li:before {
  left: auto;
  right: 15px; /* Adjust this value to increase space */
  border-left: none;
  border-right: 1px dotted #777777;
}

body[dir="rtl"] .breadcrumb li:last-child:before {
  display: none; /* Hide the separator for the last li */
  padding-left: 5rem;
}

@media screen and (max-width: 1600px) {
  div.faqWrapper {
    border-top: 1px solid #b9b9b9;
    padding: 1.3rem 0;
    /* margin-right: 5rem; */
    display: flex;
    width: 60vw;
    min-width: 5%;

    /* background-color: blue */
  }
}

@media screen and (max-width: 1300px) {
  div.faqWrapper {
    border-top: 1px solid #b9b9b9;
    padding: 1.3rem 0;
    /* margin-right: 5rem; */
    display: flex;
    width: 60vw;
    min-width: 5%;
    /* background-color: blue */
  }
}

@media screen and (max-width: 900px) {
  div.root {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    background-color: #ffffff;
  }

  div.faqWrapper {
    border-top: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 1.1rem;
    padding-left: 0px;
    justify-content: center;
    width: 100%;
  }

  div.faqWrapper:last-child {
    border-bottom: none;
  }

  p.question {
    display: block;
    width: 90%;
    margin: 0;
    color: #333;
    font-size: 16px;
    font-weight: 700;
  }

  p.question-flu {
    display: block;
    width: 90%;
    margin: 0;
    color: #333;
    font-size: 16px;
    font-weight: 700;
  }

  div.answer {
    font-size: 16px;
    font-weight: 400;
    margin: 2rem 0 1rem 0;
  }
}

/* MAIN FAQ CSS */

.main {
  background: #ffffff;
  /* padding: 0rem 3rem; */
}

.hero {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 196px;
  top: 111px;
  margin: 0 0 3rem 0;
  background-color: #e9f0f2;
}

.heroES {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 196px;
  top: 111px;
  margin: 0 0 3rem 0;
  background-color: #e9f0f2;
}

.heroTextBox {
  display: flex;
  flex-direction: column;
  margin: 3rem 0 0 7rem;
  text-align: center;
  /* align-items: center; */
  /* background-color: yellow; */
}

.heroTitle {
  display: none;
  align-items: center;
  justify-content: center;
  width: fit-content;
  /* color: #22499c; */
  font-size: 35px;
  font-weight: 700;
  margin: 0 0;
  /* background-color: green; */
}

.heroTitle a {
  text-decoration: none !important;
  color: #244c9c;
}

.heroSubTitle {
  display: none;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 30px;
  font-weight: 700;
  margin: 0 0;
  /* background-color: yellow; */
}

.heroBackground {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 20vw;
  max-width: 118px;
  max-height: 121px;
  /* background-color: red; */
  margin: 2rem 6rem 0 0;
  /* min-height: 355px; */
}

/* styles.css */
.spinner {
  border: 4px solid #f3f3f3;
  /* Light gray border */
  border-top: 4px solid #3498db;
  /* Blue border for the loading circle */
  border-radius: 50%;
  /* Create a circular shape */
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  /* Animation name, duration, and loop */

  /* Center the loading spinner */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  /* Starting position */
  100% {
    transform: rotate(360deg);
  }

  /* Ending position (360 degrees) */
}

.QAndAContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
  margin: 3rem 0 0 23rem;
}

.SearchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
  margin: 3rem 0 0 23rem;
}

.SearchBar {
  display:block;
  padding-top: 2rem;
}

.MobileSearchBar {
  display: none;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.infoBoxContainerDesktop {
  display: block;
}

.infoBoxContainerMobile {
  display: none;
}

.infoBox {
  height: auto;
  width: 50vw;
  max-width: 900px;
  display: flex;
  margin: 0;
  padding-left: 0;
  flex-direction: column;
  justify-content: center;
}

/* @media screen and (min-width: 1900px) {
    .infoBox {
      padding-left: 11vw;
    }
  } */

.infoBoxTitle {
  font-size: 45px;
  font-weight: 700;
  line-height: 40.85px;
  color: #4388c3;
  width: fit-content;
  margin-bottom: 10px;
}

.infoBoxSubtitle {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 1.5rem;
  width: fit-content;
}

.infoBoxText {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  width: fit-content;
  max-width: 1000px;
  /* background-color: red; */
}

div.breadcrumbWrapper {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

div.imageStyle a {
  color: #244c9c;
}

div.VaccineEligible {
  background-color: #dcf3ff;
  padding: 30px 20px 50px 20px;
}

div.instruction {
  font-weight: bold;
  color: #244c9c;
  display: inline-block;
  font-size: 28px;
  margin: 1rem 3rem 1rem 0.5rem;
  width: 30%;
}

button.stepCta {
  display: inline-block;
  background: #0b86bd;
  border-radius: 4rem;
  width: 25%;
  margin: 0;
  padding: 0.7rem;
  text-align: center;
  color: #fff;
  margin-right: 20px;
  font-size: 1rem;
  text-decoration: none;
  font-size: 1rem;
  border-width: 0px;
  cursor: pointer;
  max-width: 1000px;
}

div.imageStyle {
  background-repeat: no-repeat;
  background-position: left;
  margin-left: 10px;
  display: inline;
  font-size: 18px;
  background-size: 20px;
  width: 10%;
}

div.QandA {
  text-align: center;
  font-weight: bold;
  color: #244c9c;
  font-size: 30px;
  margin-bottom: 2rem;
  /* cursor: default; */
}

div.imageStyle a {
  margin-left: 1.5rem;
}

div.rtl > div.imageStyle {
  background-position: right;
}

div.rtl > div.imageStyle a {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
}

span.tabCursor {
  cursor: default !important;
}

.desktopTabs {
  display: block;
}

.mobileTabs {
  display: none;
}

/* ////////////////////////////////////////////////////////////////
  ///////////////////////Mobile///////////////////////////////////
  //////////////////////////////////////////////////////////////// */

@media screen and (max-width: 900px) {
  .main {
    padding: 0;
  }

  .QandABackground {
    background-color: #dcf3ff;
  }

  .hero {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    /* height: 196px; */
    /* min-height: 222px; */
    /* top: 111px; */
    background-color: #e9f0f2;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .heroES {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    /* height: 196px; */
    /* min-height: 222px; */
    /* top: 111px; */
    background-color: #e9f0f2;
    margin-top: 5rem;
    margin-bottom: 4rem;
  }

  .heroTextBox {
    display: flex;
    flex-direction: column;
    margin: 1.2rem 0 -4rem 0;
    text-align: center;
    align-items: center;
    /* width: 100%; */
    /* background-color: yellow; */
    background-color: #e9f0f2;
    width: 100%;
    padding-bottom: 1rem;
  }

  .heroTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    /* color: #22499c; */
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0;
    /* background-color: green; */
  }

  .QAndAContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: red; */
    margin: 0 0rem 1rem 0rem;
  }

  .SearchContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: red; */
    margin: 0 0rem 1rem 4rem;
  }

  .SearchBar {
    display: none;
  }

  .MobileSearchBar {
    display:flex;
    justify-content:center;
    width:80%;
    align-self: center;
  }

  .heroTitle a {
    text-decoration: none !important;
    color: #244c9c;
  }

  .heroSubTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0;
    /* background-color: yellow; */
  }

  .heroBackground {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 58px;
    height: 55px;
    /* background-color: red; */
    margin: 0;
    /* min-height: 355px; */
  }

  .infoBoxContainerDesktop {
    display: none;
  }

  .infoBoxContainerMobile {
    display: block;
    margin-bottom: 2rem;
  }

  .infoBox {
    height: auto;
    width: 100%;
    padding-left: 0;
    display: flex;
    margin: 2rem auto 0 auto;
    flex-direction: column;
    justify-content: center;
    /* background-color: green; */
  }

  .infoBoxTitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 40.85px;
    color: #4388c3;
    width: fit-content;
  }

  .infoBoxSubtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 1.5rem;
    width: fit-content;
  }

  .infoBoxText {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    width: fit-content;
  }

  div.VaccineEligible {
    display: flex;
    flex-direction: column;
  }

  div.instruction {
    width: 100%;
  }

  button.stepCta {
    width: 85%;
    margin-top: 15px;
  }

  .desktopTabs {
    display: none;
  }

  .mobileTabs {
    display: block;
    margin: auto;
    width: 80%;
  }

  .tabMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 53.91px;
    height: auto;
    /* width: 341.11px; */
    border: 0.78px solid #909090;
    margin: auto;
    padding: 0 15px;
    background-color: #ffffff;
  }

  span.arrow {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    width: 0;
    position: relative;
    /* height: 0; */
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid black;
    margin: 5px 2px;
  }

  span.downArrow {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    width: 0;
    position: relative;
    /* height: 0; */
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid black;
    margin: 5px 2px;
  }

  .react-tabs__tab-list {
    display: flex !important;
    flex-direction: column;
    border: 0.78px solid #909090;
    border-bottom: 1px solid #aaa !important;
    width: auto !important;
    margin: 10px 0 10px 0 !important;
    /* background-color: pink; */
  }

  .react-tabs__tab-panel {
    padding-left: 0;
    margin: auto auto;
    width: 80%;

    /* justify-content: flex-start; */
    /* background-color: green !important; */
  }

  .react-tabs__tab--selected {
    color: black !important;
    border-bottom: 0 solid #244c9c !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    margin-bottom: -5px !important;
    cursor: inherit !important;
  }

  .react-tabs__tab {
    color: #244c9c !important;
    font-weight: 700 !important;
    flex-grow: 1 !important;
    position: inherit !important;
    /* background-color: yellow !important; */
    margin-top: 10px;
  }

  .react-tabs {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: 400px) {
  .heroTextBox {
    margin: 1.2rem 0 -6rem 0;
  }
}
