/* Header Styles */

.no-border {
  border: none !important;
  outline: none !important;
}

.skip-to-content-link {
  left: 50%;
  position: absolute;
  transform: translateY(-100%);
}

.skip-to-content-link {
  background: #85460f;
  height: 30px;
  left: 50%;
  padding: 4px 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

.translationList {
  list-style-type: none;
}

.translationList li {
  display: inline-block;
  padding-right: 10px;
  cursor: pointer;
}

.translationList li:hover {
  text-decoration: none;
}

.logoDescription {
  display: inline-block;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
  color: "#22489c";
  vertical-align: middle;
}

.qrImg {
  width: 300px;
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.formContainer {
  margin: 20px 0px;
  position: relative;
}

.logo-nav-container {
  /* align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 20vh; */
  justify-content: space-between;
  padding: 5px 12vw;
}

.title-container {
  display: flex;
  padding: 15px;
}

.covid-card-header {
  color: #22489c;
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 24px;
}

.request-type-header {
  color: #22489c;
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 24px;
  align-self: left;
}

.line {
  position: absolute;
  left: 0px;
  right: 0px;

  margin: 0 14vw;
}

.font-weight-500 {
  font-weight: 500;
}

.MuiFormControl-root {
  margin-bottom: 15px;
}

.qrContainer {
  margin: 0 12vw;
}

.DashContainer {
  display: flex;
  margin: 0 14vw;
}

.LandingRoot {
  width: 100vw;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.LandingContainer {
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
}

.TitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: auto;
  min-height: 30rem;
  position: relative;
  width: 50%;
}

h1.LandingPageH1 {
  font-size: 45px;
  font-weight: bold;
  color: #22489c;
}

h2.LandingPageH2 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
}

div.HomePageImageContainer {
  align-items: center;
  display: flex;
  width: 40%;
}

img.HomePageImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  max-height: 85%;
}

.bkg {
  display: flex;
  justify-content: right;
  align-items: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 360px;
  max-height: auto;
  max-width: 100%;
  border-radius: 0;
  margin-right: 0;
  background-position: center;
  background-size: cover;
  padding: 0;
}

button.bttnStyle {
  background: #22489c;
  color: #fff;
  font-size: 18px;
  line-height: 24.51px;
  font-weight: 700;
  cursor: pointer;
  margin: 0 0 1.5rem 0;
  padding: 6px 25px;
  border-radius: 4rem;
  border: none;
}

button.bttnStyle:hover {
  background: #22489c;
  color: #fff;
}

.bodyContainer {
  min-height: 70vh;
  /* bottom = footer height */
}

.pinContainer {
  display: flex;
}

h1.pinTitle {
  color: #22489c;
  font-size: "45px";
  font-weight: bold;
  margin-bottom: 25px;
}

p.pinBody {
  font-size: 18px;
  margin-bottom: 0;
}

.qrDataItem {
  margin: 0px;
  font-size: 90%;
}

/* https://stackoverflow.com/questions/41658552/how-to-create-a-7-character-underline-input-text-field-in-html-and-cssscreensho */
#partitioned {
  border: none;
  width: 6ch;
  background: repeating-linear-gradient(
      90deg,
      dimgrey 0,
      dimgrey 1ch,
      transparent 0,
      transparent 1.5ch
    )
    0 100%/100% 2px no-repeat;
  color: dimgrey;
  font: 5ch consolas, monospace;
  letter-spacing: 0.5ch;
  font-weight: 300;
}

#lockout-blocks {
  width: 16ch;
  background: repeating-linear-gradient(
      90deg,
      #aaaaaa,
      #aaaaaa 2.7ch,
      transparent 0,
      transparent 4.129ch
    )
    0 100%/100% 70% no-repeat;
}

#partitioned:focus {
  /* outline: none; */
  color: #22489c;
}

.headerContainer {
  padding: 0px 13.5vw;
}

.logo-container-mobile {
  display: none;
}

.form-container {
  width: 100%;
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.covid-card-container {
  width: 52%;
}

.request-type-container {
  width: 85%;
}

/* Footer */

.footerLinks ul {
  list-style-type: none;
  text-align: left;
}

.footerLinks li {
  display: inline-block;
  margin-right: 20px;
  text-decoration: underline;
}

.footerLinks li:hover {
  text-decoration: none;
}

/* .footerLinks li:first-child {
  border-bottom: none;
} */

.footerLinks li a {
  color: #000;
  margin: 0;
}

.received-screen-container {
  margin: 0 14vw;
}

.invalid-page-container {
  margin: 0 14vw;
}

.center-w-margin {
  margin: 3vw;
}

.qr-title-style {
  color: #22489c;
  font-weight: bold;
  padding-top: 10px;
  font-size: 45px;
  font-family: Arial;
}

/* breadcrumbs */
.breadcrumb {
  padding-left: 0;
  background-color: transparent;
  font-size: 16px;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;

  li:before {
    content: "";
    border-left: 1px dotted #777777;
    margin: 0 15px;
    font-size: 10px;
    position: relative;
    top: -2px;
  }

  > li + li:before {
    padding: 0;
  }

  li:first-child:before {
    border-left: none;
    margin: 0 0;
  }

  .list-group-flush a {
    color: #004abc;
  }

  .list-group-flush a,
  a,
  a:hover,
  a:visited {
    color: #004abc;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  a.list-group-item {
    padding: 20px;
  }
}

.qr-flex {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.pt-2 {
  width: 50%;
}

hr {
  margin: 0.35rem 0;
}

.actual-qr-img {
  display: "block";
  margin-left: -14px;
}

.smarthealthcard-container {
  width: 290px;
}

.save-buttons {
  display: flex;
}

#desktop-save {
  display: block;
}

.mobile-save {
  display: none;
}

#apple-health-button {
  width: 283px;
  margin-top: 10px;
}

.dataDiv {
  width: 50%;
}

#save-image-button {
  height: 55px;
  margin-left: 0px;
  width: 280px;
}

@media only screen and (max-width: 1050px) {
  .received-screen-container {
    margin: 0px 5vw;
    padding-top: 20px;
  }

  .DashContainer {
    margin: 0 5vw;
  }

  /* .footerContainer {
    margin: 0 5vw;
  } */

  .headerContainer {
    padding: 0px 5vw;
  }

  .subheaderContainer,
  .vaccineLogo {
    margin: 0 !important;
  }

  .logo-nav-container {
    padding: 5px 3vw;
  }

  .qrContainer {
    margin: 0 2vw;
  }
}

@media only screen and (max-width: 950px) {
  /* .MuiInputLabel-formControl, .MuiInput-input {
        font-size: 0.8rem !important;
    }
    .MuiSelect-selectMenu {
        height: 0px !important;
    } */
  .footerLinks li {
    display: block;
    border-bottom: none;
    padding-top: 20px;
  }

  .LandingContainer {
    margin-bottom: 25rem;
  }

  .TitleContainer {
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    width: 90%;
    height: auto;
    position: absolute;
    top: 24rem;
    box-shadow: 0px 4px 10px 0px #00000040;
    border-radius: 8px;
    z-index: 99;
    background-color: white;
    min-height: auto;
    padding: 2rem;
  }

  div[dir="rtl"] .TitleContainer {
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    height: auto;
    position: absolute;
    top: 24rem;
    box-shadow: 0px 4px 10px 0px #00000040;
    border-radius: 8px;
    z-index: 99;
    background-color: white;
    min-height: auto;
    padding: 2rem;
  }

  h1.LandingPageH1 {
    font-size: 35px;
  }

  .TitleContainer.rtl {
    text-align: right;
    direction: rtl; /* Right-to-left */
  }

    /* Default alignment */
  .TitleContainer {
    text-align: left;
    direction: ltr; /* Left-to-right */
  }


  h2.LandingPageH2 {
    font-size: 20px;
  }

  button.bttnStyle {
    margin-bottom: 3rem;
  }

  p.LandingPagePara {
    font-size: 16px;
  }
}

@media only screen and (max-width: 876px) {
  #dose-data-container {
    width: 100%;
  }

  #vaccine-data-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .LandingContainer {
    margin-bottom: 30rem;
  }
}

@media only screen and (min-width: 552px) {
  .qrDiv {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  #patient_number > div {
    font-size: 0.9rem !important;
  }

  .logo-nav-container {
    align-items: unset;
    justify-content: center;
  }

  .logo-container-desktop {
    display: none;
  }

  .logo-container-mobile {
    display: block;
    text-align: center;
  }

  .covid-card-container {
    width: 100%;
  }

  .translationList {
    padding-left: 0px;
  }

  .translationList li {
    padding-left: 0px;
  }

  .qrContainer {
    margin: unset;
  }

  .center-w-margin {
    text-align: center;
  }

  .qr-flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .pt-2 {
    width: 100%;
  }

  .actual-qr-img {
    margin-left: 0px;
  }

  .qrImg {
    margin-left: 14px;
    width: 293px;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .smarthealthcard-container {
    margin-left: 14px;
    width: 293px;
  }

  .save-buttons {
    justify-content: center;
  }

  #print-button {
    display: none;
  }

  #desktop-save {
    display: none;
  }

  .mobile-save {
    display: block;
    margin-bottom: 5px;
  }

  .breadcrumb {
    display: none;
  }

  .breadcrumb-container {
    display: none;
  }

  .qr-title-style {
    color: #22489c;
    font-weight: bold;
    padding-top: 10px;
    font-size: 25px;
    font-family: Arial;
  }

  .dataDiv {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .save-resize {
    flex-direction: column;
  }
}

@media only screen and (max-width: 425px) {
  .DashContainer {
    padding: 0vh;
  }

  .translationList li {
    display: inline-block;
    cursor: pointer;
  }

  .form-select {
    padding: 0px 5px;
  }

  .pinContainer {
    justify-content: center;
  }

  .received-screen-container {
    padding-top: 0px;
  }

  .invalid-page-container {
    padding-top: 0px;
  }

  .qrContainer {
    display: unset;
    justify-content: unset;
  }

  .LandingContainer {
    margin-bottom: 40rem;
  }
}

@media only screen and (max-width: 300px) {
  .LandingContainer {
    margin-bottom: 50rem;
  }
}
