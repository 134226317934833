.root {
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-repeat: no-repeat;
  background-position: top right;
  margin: auto;
  position: relative;
  background-size: cover;
  padding-bottom: 2rem;
  transition: 0.4s ease;
  background-color: transparent;
}

.mobileRoot {
  line-height: 0px;
}

.carouselContainer {
  /* background-color: orange; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  transition: transform 0.5s ease;
  overflow: hidden;
}

.carouselContent {
  display: flex;
  transition: transform 1.5s ease;
  height: 100%;

}

.componentClassName {
  flex: 0 0 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
  padding: 0;
  margin: 0;
  width: 100%;
  /* font-size: 60px !important; */
  /* height: 485px; */
  /* opacity: 0; */
  /* visibility: visible; */
  /* background-color: red; */
}

div.arrowContainer {
  max-width: 1440px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 500;
  top: calc(50% - 2.5rem);
  text-align: center;
}

img.arrow {
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  margin-left: 1rem;
}

img.rightArrow {
  transform: scaleX(-1);
  margin-left: 0;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  margin-left: 1rem;
}

img.arrowRtl {
  transform: scaleX(-1);
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  margin-left: 1rem;
}

img.rightArrowRtl {
  margin-left: 0;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  border-radius: 2%;
  cursor: pointer;
  margin-left: 1rem;
}

.dotContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100vw;
  max-width: 100%;
  margin: 15px 0;
  align-items: center;
  top: calc(100% - 2.5rem);
  left: calc(1% - 2rem);
}

img.pauseButtonStyle {
  margin: 0 10px;
}

img.pauseButtonStyle:hover {
  cursor: pointer;
}

span.dot1 {
  display: flex;
  flex-direction: row;  
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  /* background-color: #22499C; */
  margin: 0 10px;
}

span.dot2 {
  display: flex;
  flex-direction: row;  
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  /* background-color: gray; */
  margin: 0 10px;
}

span.dot3 {
  display: flex;
  flex-direction: row;  
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  /* background-color: gray; */
  margin: 0 10px;
}

span.dot4 {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: gray;
}

span.dot5 {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: gray;
}

span.dot6 {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: gray;
}

.card {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  width: 60%;
  height: 200px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  border: 1px solid silver;
  transition: 0.4s ease;
}

.componentClassName {
  /* display: flex;
  flex-direction: row; */
  flex:1
  /* overflow-x: visible; */
}

/* YourComponent.css */
.carouselContainer {
  display: flex;
}

.equal-size-component {
  flex: 1;
}

div.mobileView {
  box-shadow: 0px 4px 10px 0px #00000040;
}
