div.root {
  background-color: #22499c;
  padding: 4rem 0 4rem 0;
  display: flex;
  justify-content: center;
}

div.blueApptContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  width: 100%;
}

div.title {
  color: white;
  font-size: 35px;
  font-weight: 700;
}

div.title p{
  margin: 0 0 20px 0;
}

div.buttonContainer {
  padding-bottom: 0;
}

button.buttonStyle {
  background-color: white;
  color: #22499c;
  border-radius: 2.7rem;
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  height: 39px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
  cursor: pointer;
}

.buttonStyle a {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: none;
  color: #22499c;
}

.bottomLink {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
}

span.arrowRight {
  display: flex;
  justify-content: right;
  align-items: bottom;
  background-repeat: no-repeat;
  width: 10px;
  height: 12px;
  margin: 0 auto 0 10px;
  display: inline-block;
  content: "";
}

@media screen and (max-width: 500px) {
  div.root {
    padding: 2rem 1rem;
  }

  div.title {
    text-align: center;
    font-size: 25px;
  }

  /* button.buttonStyle {
    width: 12rem;
  } */

  p.bottomLink {
    text-align: center;
  }

  .bottomLink {
    font-size: 16px;
  }
}
