.formBodyContainer {
    display: flex;
    width: 100%;
    margin: auto;
    margin-top: 3rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.formHeaderContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formHeaderTitle {
    color: #22489C;
    font-weight: bold;
    font-size: 45px;
    text-align: center;
}

.formHeaderSubtitle {
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.formHeaderText {
    color: black;
    font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    width: 85%;
}

.selectOptionContainer {
    width: 85%;
}

.selectOptionTitle {
    color: #22489C;
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 24px;
    align-self: left;
    margin-top: 2rem;
}

.fieldContainer {
    width: 85%;
}
