div.root {
    background: #EAF8FE;
    display: flex;
    justify-content: center;
}

div.rootMyTurn {
  background: #FDF1D0;
  display: flex;
  justify-content: center;
}

div.bannerWrapper {
    margin: 1rem;
    flex-direction: row;
    display: flex; 
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
  }
  div.textContainer {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    display: inline;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
  }
  
  img.alertImage {
    padding-top: 5px;
    width: 30px;
  }
  
  span.header {
    background: inherit;
    text-align: center;
    font-weight: bold;
    font-family:Arial, Helvetica, sans-serif;
    margin-right: 8px;
  }

  span.body {
    background: inherit;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  div.infoImage {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px;
    /* filter: grayscale(100%); */
    /* margin-left: 35px; */
    margin-right: -15px;
  }
    
  img.close {
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    background-color: transparent;
    padding: 0;
  }

  button.close {
    cursor: pointer;
    margin: 0 10px;
    position: relative;
    background-color: transparent;
  }
  
  @media screen and (max-width: 950px) {
    div.root {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }

    div.rootMyTurn {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
  }

    div.textContainer {
      flex-direction: column;
    }
    img.alertImage {
        display: none;
    }
  }
  
  @media screen and (max-width: 700px) {
    img.alertImage {
      width: 20px;
      padding-top: 15px;
    }
    
    img.close {
      width: 16px;
      padding-top: 15px;
    }
  }
  
  @media screen and (max-width: 600px) {
    img.alertImage {
      width: 20px;
      padding-top: 30px;
    }

    img.close {
      width: 16px;
      padding-top: 30px;
    }
  }