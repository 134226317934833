.root {
    max-width: 1440px;
    width: 100%;
    margin: auto;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: auto;
  }
  
  .titlesContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0;
  }
  
  .title {
    color: #4388c3;
    font-family: Helvetica;
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 25px;
    text-align: center;
  }
  
  .subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
  }
  
  .bodyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .vaccineTitles {
    font-size: 25px;
    color: #4388c3;
    font-family: Helvetica;
    font-weight: 700;
    margin-bottom: 4rem;
    max-width: 300px;
    height: 75px;
    text-align: center;
  }
  
  .vaccineTitles:hover {
    cursor: pointer;
  }
  
  .vaccineTitles a{
    text-decoration: none;
    color: inherit;
  }
  
  img.arrowRight {
    width: 10px;
    margin-left: 1rem;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25vw;
    max-width: 440px;
    height: 340px;
    border-radius: 10px;
    box-shadow: 0px -3px 8px 1px #ACACAC80;
    padding: 0 30px;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: white;
  }
  
  .cardContainer:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0 ,0.3);
  }
  
  .cardTitleButton {
    border-radius: 25px;
    border: 2px solid transparent;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    min-height: 42px;
    letter-spacing: 0px;
    text-align: left;
    margin: 30px 0;
  }
  
  .cardText {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 2rem;
    color: black;
  }
  
  .learnMoreText {
    text-decoration: none;
    color: #1476BD;
    font-size: 16px;
    font-weight: 700;
  }
  
  img.arrowRightSmall {
    width: 8px;
    margin-left: 10px;
    margin-bottom: -1.5px;
    /* background-color: red; */
  }
  
  .bottomTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    width: 50%;
    margin: 4rem auto;
    background-color: white;
    text-align: center;
  }
  
  .bottomTextLink {
    color: #0078C1;
    text-decoration: none;
    margin-left: 1.5rem;
  }
  
  body[dir="rtl"] .cardText {
    direction: rtl;
    text-align: right;
  }

  body[dir="rtl"] .cardTitleButton {
    direction: rtl;
    text-align: right;
  }

  @media screen and (max-width: 900px) {
    .titlesContainer {
      margin: 3rem 0 2rem 0;
    }
    
    .title {
      font-size: 25px;
    }
  
    .subtitle {
      font-size: 16px;
    }
  
    .vaccineTitlesContainerMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .vaccineTitles {
      font-size: 20px;
      margin-bottom: 1.5rem;
    }
  
    img.arrowRight {
      width: 10px;
      height: 12px;
    }
  
    .cardContainerMobile {
      margin: 2rem 0 0 0;
    }
  
    .singleCardMobile {
      width: 80vw;
      max-width: 380px;
      border-radius: 10px;
      box-shadow: 0px 0px 20px 0px #ACACAC80;
      background-color: white;
      margin-bottom: 2rem;
      
    }
  
    .cardHeaderMobile {
      border-radius: 10px 10px 0 0;
      text-align: center;
      color: white;
      padding: 10px 0;
      font-size: 16px;
      font-weight: 700;
    }
  
    .cardTextContainerMobile {
      height: 135px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 0 2rem;
    }
  
    /* .cardText{
      font-size: 16px;
      text-align: center;
    } */
  
    .cardTextMobile {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
  
    img.arrowRightSmall {
      width: 8px;
      height: 10px;
      margin-bottom: .5px;
    }
  
    .learnMoreText {
      font-size: 16px;
      font-weight: 700;
    }
  
  
    .bottomTextContainer {
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      width: auto;
      margin: 1rem 1rem 5rem 1rem;
    }
  
    .bottomText {
      text-align: center;
    }
  
    .bottomTextLink {
      margin-top: 10px;
      margin-left: 0;
    }
    
  }
  
  